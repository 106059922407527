<ul class="notification-list">
    <div *ngFor="let notif of notifications | slice:0:5" class="notification-item" [ngClass]="{'new': !notif.read_at}" [ngSwitch]="notif.data.type" (click)="redirect(notif)">
        <div class="notification-item-content" *ngSwitchCase="0">
            <h6 class="mt-0 mb-1 title">Your have new order</h6>
            <p class="mb-2">You have new order #{{notif.data.order_id}}<br>Click to check your order.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="1">
            <h6 class="mt-0 mb-1 title">Order has been paid</h6>
            <p class="mb-2">Order #{{notif.data.order_id}} has been paid<br>Click to check your order.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="2">
            <h6 class="mt-0 mb-1 title">You have sent an order</h6>
            <p class="mb-2">Order #{{notif.data.order_id}} has been sent. <br>Click to check your order.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="3">
            <h6 class="mt-0 mb-1 title">Order has been delivered</h6>
            <p class="mb-2">Order #{{notif.data.order_id}} has been delivered. <br>Click to check your order.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="4">
            <h6 class="mt-0 mb-1 title">Your order has been settled</h6>
            <p class="mb-2">Your Order #{{notif.data.order_id}} has been settled. <br>Click to check your order.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="5">
            <h6 class="mt-0 mb-1 title">Your order has been cancelled</h6>
            <p class="mb-2">Your Order #{{notif.data.order_id}} has been cancelled. <br>Click to check your order.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="6">
            <h6 class="mt-0 mb-1 title">You have reported order</h6>
            <p class="mb-2">You have reported Order #{{notif.data.order_id}}. <br>Click to check your order.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="7">
            <h6 class="mt-0 mb-1 title">Your order has been resolved</h6>
            <p class="mb-2">Your Order #{{notif.data.order_id}} has been resolved. <br>Click to check your order.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="8">
            <h6 class="mt-0 mb-1 title">New discussion</h6>
            <p class="mb-2">There are new discussion on product #{{notif.data.product_id}} discussion. <br>Click to check.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="9">
            <h6 class="mt-0 mb-1 title">New beautybook comment</h6>
            <p class="mb-2">There are new comments on your beautybook post. <br>Click to check.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="10">
            <h6 class="mt-0 mb-1 title">New like on your post</h6>
            <p class="mb-2">There are new like on your beautybook post. <br>Click to check.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="11">
            <h6 class="mt-0 mb-1 title">Product in Approval Process</h6>
            <p class="mb-2">Your product #{{notif.data.product_id}} is in approval process. <br>Click to check.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="12">
            <h6 class="mt-0 mb-1 title">Product Approved</h6>
            <p class="mb-2">Your product #{{notif.data.product_id}} has been approved. <br>Click to check.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="13">
            <h6 class="mt-0 mb-1 title">Product Rejected</h6>
            <p class="mb-2">Your product #{{notif.data.product_id}} has been rejected. <br>Click to check.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="14">
            <h6 class="mt-0 mb-1 title">Welcome to Beautybook</h6>
            <p class="mb-2">Verify your email now to get special voucher.
                <br>Check your email now.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="15">
            <h6 class="mt-0 mb-1 title">Checkout created</h6>
            <p class="mb-2">Checkout #{{notif.data.checkout_id}} has been
                created. <br>Finish your payment now.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="16">
            <h6 class="mt-0 mb-1 title">Checkout Rejected</h6>
            <p class="mb-2">Checkout #{{notif.data.checkout_id}} has been
                rejected. <br>Contact our customer support for more informations.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="24">
            <h6 class="mt-0 mb-1 title">Thanks for your payment</h6>
            <p class="mb-2">Checkout #{{notif.data.checkout_id}} has been
                paid. <br>We will proceed your order right away.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="18">
            <h6 class="mt-0 mb-1 title">Happy Pay top-up confirmed</h6>
            <p class="mb-2">Happy Pay top-up
                #{{notif.data.happy_pay_top_up_id}} confirmed. <br>Click to check.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="19">
            <h6 class="mt-0 mb-1 title">Happy Pay top-up rejected</h6>
            <p class="mb-2">Happy Pay top-up
                #{{notif.data.happy_pay_top_up_id}} rejected. <br>Click to check.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="21">
            <h6 class="mt-0 mb-1 title">Happy Pay withdrawal confirmed</h6>
            <p class="mb-2">Happy Pay withdrawal
                #{{notif.data.happy_pay_top_up_id}} confirmed. <br>Click to check.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="22">
            <h6 class="mt-0 mb-1 title">Happy Pay withdrawal rejected</h6>
            <p class="mb-2">Happy Pay withdrawal
                #{{notif.data.happy_pay_top_up_id}} rejected. <br>Click to check.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchCase="23">
            <h6 class="mt-0 mb-1 title">New HappyPay Adjustment</h6>
            <p class="mb-2">we have adjusted your HappyPay balance.
                <br>Click to check.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
        <div class="notification-item-content" *ngSwitchDefault>
            <h6 class="mt-0 mb-1 title">Unhandled Notifications</h6>
            <p class="mb-2">Unhandled Notifications.</p>
            <small>{{notif.timeStamp}}</small>
        </div>
    </div>
    <div *ngIf="notifications.length < 1" class="no-notification">
        <div class="text">No Notification</div>
    </div>
</ul>